export enum Roles {
    GUEST = 0,
    REGISTERED = 101,
    ANNOTATOR = 201,
    ANNOTATOR_VIEW_ONLY = 202,
    PROJECTOWNER = 301,
    REVIEWER = 401,
    REVIEWER_VIEW_ONLY = 402,
    VALIDATOR = 501,
    MANAGER = 601,
    GROUPADMIN = 701,
    BUSINESSADMIN = 801,
    ADMIN = 901
}
